/* Основные стили для компонента */
*,
*::before,
*::after {
  box-sizing: border-box; /* Обеспечиваем правильное поведение ширины */
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Контейнер для снежинок */
.snowflakeContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; /* Не блокируем клики */
  z-index: 9999;
}

/* Стили для снежинок */
.snowflake {
  position: absolute;
  top: -10px;
  font-size: 12px; /* Уменьшаем размер снежинок */
  color: rgba(0, 191, 255, 0.6); /* Голубой цвет с прозрачностью */
  user-select: none;
  pointer-events: none;
  animation: snowfall linear infinite;
  opacity: 0.5; /* Уменьшаем видимость */
}

@keyframes snowfall {
  to {
    transform: translateY(100vh) rotate(360deg);
  }
}

/* Разные скорости падения */
.snowflake:nth-child(odd) {
  animation-duration: 18s; /* Медленная скорость для более мягкого эффекта */
}

.snowflake:nth-child(even) {
  animation-duration: 20s; /* Ещё медленнее */
}

/* Рандомизация анимации для снежинок */
.snowflake {
  left: calc(100% * random());
  opacity: random(0.3, 0.6); /* Дополнительная рандомизация прозрачности */
}

/* Структура компонента */
.template {
  position: relative;
  min-height: 100vh;
  background-color: #f0f0f0;
  overflow: hidden;
}

/* Основные стили для компонента */
.menu {
  margin-top: 20px;
}

.body {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
  max-width: 100%; /* Убедитесь, что контейнер не выходит за пределы */
}

.item {
  flex: 1;
}

.left,
.right {
  width: 20%;
}

.centr {
  width: 60%;
}

/* Адаптивные стили */
@media (max-width: 768px) {
  .body {
    flex-direction: column;
  }
  .left,
  .right {
    width: 100%;
  }
  .centr {
    width: 100%;
  }
}

  