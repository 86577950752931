.menu {
    display: flex;
    justify-content: space-around;
    background-color: #333;
    padding: 10px 0;
}

.menu a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

.menu a:hover {
    background-color: #555;
    border-radius: 5px;
}

.menu a.active {
    background-color: #007bff;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .menu {
        flex-direction: column;
        align-items: center;
    }

    .menu a {
        padding: 15px 0;
        font-size: 18px;
    }
}