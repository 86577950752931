/* Основной контейнер страницы */
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .tarifList {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px; 
  }
  
  .tarifItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    border-left: 6px solid #4CAF50;
  }
  
  .tarifItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .speed {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .price {
    font-size: 20px;
    font-weight: bold;
    color: #4CAF50;
    background-color: #e8f5e9;
    padding: 10px 20px;
    border-radius: 8px;
  }
  
  /* Адаптивные стили для мобильных устройств */
  @media (max-width: 768px) {
    .tarifList {
      flex-direction: column;
      gap: 15px;
    }
  
    .tarifItem {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  
    .speed {
      font-size: 20px;
    }
  
    .price {
      font-size: 18px;
      align-self: flex-end;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 24px;
    }
  
    .tarifItem {
      padding: 15px;
    }
  
    .speed {
      font-size: 18px;
    }
  
    .price {
      font-size: 16px;
    }
  }
  