/* Основной контейнер */
.list {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  h2 {
    font-size: 28px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .list li {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #555;
    background-color: #fff;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .list li:hover {
    background-color: #4CAF50;
    color: #fff;
    transform: scale(1.02);
  }
  
  .list li b {
    margin-left: 10px;
    font-weight: bold;
  }
  
  .list li:last-child {
    margin-bottom: 0;
  }
  
  /* Адаптивный дизайн для мобильных устройств */
  @media (max-width: 768px) {
    .list {
      padding: 15px;
    }
  
    .list li {
      font-size: 16px;
      padding: 12px;
    }
  
    h2 {
      font-size: 24px;
    }
  }
  
  @media (max-width: 480px) {
    .list {
      padding: 10px;
    }
  
    .list li {
      font-size: 14px;
      padding: 10px;
    }
  
    h2 {
      font-size: 20px;
    }
  }
  