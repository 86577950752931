/* Основной контейнер */
.block {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .inputuser {
    width: 80%;
    padding: 15px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    transition: border-color 0.3s;
  }
  
  .inputuser:focus {
    border-color: #4CAF50;
    outline: none;
  }
  
  .getinfo {
    width: 80%;
    padding: 15px;
    font-size: 16px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .getinfo:hover {
    background-color: #45a049;
  }
  
  .user {
    margin-top: 20px;
  }
  
  .userInfo {
    list-style: none;
    padding: 0;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
  }
  
  .userInfo li {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .userInfo li:last-child {
    margin-bottom: 0;
  }
  
  .not {
    font-size: 16px;
    color: #ff4b4b;
    margin-top: 20px;
  }
  
  .error {
    font-size: 14px;
    color: #ff4b4b;
    margin-top: 10px;
  }
  
  /* Адаптивность для мобильных устройств */
  @media (max-width: 768px) {
    .block {
      padding: 15px;
    }
  
    .inputuser {
      padding: 12px;
    }
  
    .getinfo {
      padding: 12px;
    }
  
    .title {
      font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .block {
      padding: 10px;
    }
  
    .inputuser {
      padding: 10px;
    }
  
    .getinfo {
      padding: 10px;
    }
  
    .title {
      font-size: 18px;
    }
  }
  