/* Основные стили для компонента */
.articles {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  h4 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
    margin-bottom: 20px;
  }
  
  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Адаптивность для мобильных устройств */
  @media (max-width: 768px) {
    .articles {
      margin: 10px;
      padding: 15px;
    }
  
    h4 {
      font-size: 20px;
      text-align: center;
    }
  
    p {
      font-size: 14px;
    }
  
    img {
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  
  /* Адаптивность для очень маленьких экранов */
  @media (max-width: 480px) {
    .articles {
      margin: 5px;
      padding: 10px;
    }
  
    h4 {
      font-size: 18px;
    }
  
    p {
      font-size: 12px;
    }
  }
  