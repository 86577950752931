/* Основной контейнер */
.service {
    width: 100%;
    padding: 20px;
    background-color: #f4f4f9;
    font-family: 'Arial', sans-serif;
    box-sizing: border-box;
  }
  
  .title {
    font-size: 24px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .tableContainer {
    width: 100%;
    overflow-x: auto; /* 🔥 Добавлена прокрутка */
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 600px; /* Минимальная ширина таблицы */
  }
  
  .table th, 
  .table td {
    padding: 15px;
    text-align: center;
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
    /* 🔥 Новый стиль для переноса текста */
    white-space: normal; 
    word-break: break-word; 
  }
  
  .table th {
    background-color: #4CAF50;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .table tr:last-child td {
    border-bottom: none;
  }
  
  .table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .table td {
    font-size: 16px;
  }
  
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* 🔥 Адаптивность для мобильных устройств */
  @media (max-width: 768px) {
    .tableContainer {
      overflow-x: auto; /* 🔥 Прокрутка по горизонтали */
    }
  
    .table {
      min-width: 100%; /* Убираем фиксированную ширину */
    }
  
    .table th, 
    .table td {
      padding: 10px;
      font-size: 14px;
      white-space: normal; /* Разрешить перенос текста */
      word-break: break-word; /* Разбивать длинные слова */
    }
  
    .title {
      font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .tableContainer {
      overflow-x: auto; /* 🔥 Прокрутка по горизонтали */
    }
  
    .table {
      min-width: 100%; /* Убираем фиксированную ширину */
    }
  
    .table th, 
    .table td {
      padding: 10px;
      font-size: 14px;
      white-space: normal; /* Разрешить перенос текста */
      word-break: break-word; /* Разбивать длинные слова */
    }
  
    .title {
      font-size: 18px;
    }
  }
  
  