/* Общие стили для компонента */
.footer {
    text-align: center;
    margin: 20px;
  }
  
  .footerImage {
    width: 100%;
    height: auto;
    max-width: 600px; /* Ограничиваем максимальную ширину картинки */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .footerImage:hover {
    transform: scale(1.05); /* Эффект увеличения при наведении */
  }
  
  /* Адаптивные стили для мобильных устройств */
  @media (max-width: 768px) {
    .footerImage {
      max-width: 100%; /* Картинка будет занимать всю ширину на мобильных устройствах */
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      margin: 10px;
    }
  }
  